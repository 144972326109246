<template>
    <wizard-layout>
        <div class="wizard-faq">
            <div class="wizard__title-wrapper wizard-faq__wrapper-header">
                <div>
                    FAQ
                </div>
                <img
                    src="@/assets/img/svg/icon-extension.svg"
                    alt="icon extension"
                    style="cursor: pointer"
                    @click="openWizardModal('FAQ')"
                >
            </div>
            <div class="page-faq wizard__form">
                <faq-list
                    :show-search="true"
                    :is-page="false"
                />
            </div>
            <div class="wizard-content__buttons wizard-content__buttons--faq">
                <custom-button
                    default
                    class="button btn-base_colored sort-button"
                    @on-btn-click="$emit('goToPrevStep', $event)"
                >
                    Previous
                </custom-button>
                <custom-button
                    default
                    @on-btn-click="$emit('goToNextStep')"
                >
                    next
                </custom-button>
            </div>
        </div>
        <wizard-modal ref="wizard-modal">
            <template slot="wizard-content">
                <faq-list
                    :show-search="true"
                    :is-page="false"
                />
                <div class="wizard-content__buttons wizard-content__buttons--faq">
                    <custom-button
                        default
                        class="button btn-base_colored sort-button"
                        @on-btn-click="$emit('goToPrevStep', $event)"
                    >
                        Previous
                    </custom-button>
                    <custom-button
                        default
                        @on-btn-click="$emit('goToNextStep')"
                    >
                        next
                    </custom-button>
                </div>
            </template>
        </wizard-modal>
    </wizard-layout>
</template>

<script>
import FaqList from '@/components/common/faq/FaqList'
import WizardModal from '@/components/modals/WizardModal';

export default {
    name: 'Index',
    metaInfo: {
        title: 'Wizard FAQ'
    },
    components: {
        FaqList,
        WizardModal
    },
    methods: {
        openWizardModal(title) {
            this.$refs['wizard-modal'].open(title)
        }
    }
}
</script>

<style lang="scss">
    .wizard-faq{
        background-color: $white;
    }
    .page-faq {
        height: 700px;
        overflow-y: scroll;
    }

    .wizard-faq__wrapper-header{
        padding: 40px;
        margin-bottom: 20px;
        border-bottom: 1px solid #EAEAEA;
        font-weight: bold;
        font-size: 24px;
        line-height: 40px;
        color: #181818;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }
    .wizard-content__buttons--faq{
        padding: 20px;
        border-top: 1px solid #EAEAEA;
    }
</style>
