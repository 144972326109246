<template>
    <form
        action=""
        class="search"
    >
        <input
            v-model="searchText"
            type="text"
            placeholder="Search for..."
            class="search__input"
            @keyup="search"
        >
        <i class="search_btn" />
        <span
            v-show="searchText.length"
            class="close-button"
            @click="clearSearch()"
        />
        <!-- <button class="btn-base btn-base_normal btn-base_orange search__button" @click.prevent="search">
            Search
        </button> -->
    </form>
</template>

<script>
import { eventBus } from '@/helpers/event-bus/index.js'

export default {
    data() {
        return {
            showFilter: false,
            searchText: ''
        }
    },
    methods: {
        clearSearch() {
            this.searchText = ''
            eventBus.$emit('searchFaq', this.searchText)
        },
        search() {
            eventBus.$emit('searchFaq', this.searchText)
        }
    }
}
</script>
