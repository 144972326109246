<template>
    <main-layout>
        <template
            v-if="!isPage"
            #header
        >
            <div />
        </template>
        <template #content>
            <div class="faq-wrapper">
                <the-faq-page-search v-if="showSearch" />
                <ul class="faq-list">
                    <li
                        v-for="(answer, index) in listSearch.slice(0, countQuestion)"
                        :key="index"
                        class="faq-list-item"
                        :class="{ 'faq-list-item--active' : activeQuestion === index }"
                    >
                        <div
                            class="faq-list-item_header"
                            @click.prevent="toggle(index)"
                        >
                            {{ answer.q }}
                        </div>

                        <div
                            v-show="activeQuestion === index"
                            class="faq-list-item_answer"
                        >
                            <span v-html="answer.a" />
                        </div>
                    </li>

                    <span
                        v-if="countQuestion < list.length && showButton"
                        class="btn-base btn-base_normal btn-base_orange"
                        @click="showMore"
                    >Show more</span>
                </ul>
                <transition name="fade">
                    <not-found
                        v-if="!listSearch.length"
                        text="Not found"
                    />
                </transition>
            </div>
        </template>
        <template
            v-if="!isPage"
            #footer
        >
            <div />
        </template>
    </main-layout>
</template>

<script>
import { eventBus } from '@/helpers/event-bus/index.js'
import { FaqData } from '@/helpers/faqDataClass.js'
import TheFaqPageSearch from '@/components/common/faq/TheFaqPageSearch'
import NotFound from '@/components/NotFound'

import MainLayout from '@/layouts/MainLayout.vue'

export default {
    components: {
        MainLayout,
        TheFaqPageSearch,
        NotFound
    },
    props: {
        isPage: {
            type: Boolean,
            default: true
        },
        count: {
            type: Number,
            default: 100
        },
        showButton: {
            type: Boolean,
            default: false
        },
        showSearch: {
            type: Boolean,
            default: true
        },
        keyword: {
            type: String,
            default: 'essay'
        },
        title: String
    },
    data() {
        return {
            activeQuestion: null,
            countQuestion: this.count,
            search: ''
        }
    },
    computed: {
        listSearch() {
            return this.list.filter((item) => item.q.toLowerCase().includes(this.search.toLowerCase()))
        },
        list() {
            const faqData = new FaqData(this.keyword)
            const faqText = faqData.getData()
            return faqText
        }
    },
    mounted() {
        eventBus.$on('searchFaq', (text) => {
            this.search = text
        })
    },
    methods: {
        toggle(val) {
            this.activeQuestion = this.activeQuestion === val ? null : val
        },
        showMore() {
            if (this.countQuestion < this.list.length) {
                this.countQuestion += 3
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.faq-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 56px;
    color: $white;
}

.faq-wrapper{
    max-width: 1200px;
    margin: 0 auto;
}

@media (max-width: 1200px){
    .faq-wrapper {
        margin: 0 15px;
    }
}

.faq-list-item--active {
    box-shadow: 0 5px 30px rgb(0 0 0 / 20%);
}

</style>
